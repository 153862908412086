import React from 'react'
import EnLayout from '../components/en_layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import Estilos from '../components/mainContent/container.module.css'
import Imagen500esemiautomaticabandejamanual from '../components/imageComponents/imagen5000Esemiautomaticabandejamanual'

const IndexPage = () => (
  <EnLayout>
    <SEO title='5000 E SEMI-AUTOMATIC' />
    <Header siteTitle='OR-PRINTER Semi-automatic with manual tray 5010' />

    <Container>
      <div className='row justify-content-md-center'>
        <div className='col-12 col-md-6 text-center'>
            <Imagen500esemiautomaticabandejamanual  />
          
        </div>
        <div className='col-12 col-md-6 conmargen'>
      <h3 className={Estilos.username}>Technical specifications:</h3>
            <ul className='aplicaciones'>
            <li> Semi-automatic machine driven by electric motor. </li>
                  <li> Manual or pedal tray work systems. </li>
                  <li> Power supply: 230 V. </li>
                  <li> Maximum electrical power: 750 W. </li>
                  <li> Maximum Pressure: 1300 kgs. </li>
                  <li> Maximum piece height: 65 mm. </li>
                  <li> Maximum printing area: 200 X 100 mm. </li>
                  <li> Work rate up to 1100 impressions / hour. </li>
                  <li> Digital temperature controller P.I.D. up to 300 & deg; C. </li>
                  <li> Film advance by electric motor controlled by electronic timer. </li>
                  <li> Self-tilting head, adjustable in height and inclination. </li>
                  <li> Progressive, backward and totalizer digital counter. </li>
                  <li> Weight: 62 Kg. </li>
                  <li> Dimensions: Height: 75 cms. Width: 50 cms. Depth: 60 cms. </li>
                </ul>
        </div>
      </div>
    </Container>
  </EnLayout>
)

export default IndexPage
